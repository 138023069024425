(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict"; // legacy browser alert

function isLegacyIE() {
  var ua = navigator.userAgent;

  if (ua.indexOf("MSIE 6.0") != -1) {
    // Internet Explorer 6
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 7.0") != -1) {
    // Internet Explorer 7
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 8.0") != -1) {
    // Internet Explorer 8
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 9.0") != -1) {
    // Internet Explorer 9
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 10.0") != -1) {
    // Internet Explorer 10
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else {
    return false;
  }
}

isLegacyIE(); //ハンバーガメニュー

(function ($) {
  $(function () {
    var $header = $("#header"); // Nav Toggle Button

    $("#nav_toggle").click(function () {
      $header.toggleClass("open");
    });
    $("nav a").on("click", function () {
      if (window.innerWidth <= 768) {
        $header.removeClass("open");
      }
    });
  });
})(jQuery); // ふわっと出現


$(function () {
  function animation() {
    $(".fadeInUp").each(function () {
      //ターゲットの位置を取得
      var target = $(this).offset().top; //スクロール量を取得

      var scroll = $(window).scrollTop(); //ウィンドウの高さを取得

      var windowHeight = $(window).height(); //ターゲットまでスクロールするとフェードインする

      if (scroll > target - windowHeight) {
        $(this).css("opacity", "1");
        $(this).css("transform", "translateY(0)");
      }
    });
  }

  animation();
  $(window).scroll(function () {
    animation();
  });
}); //ページ内リンクスムーススクロール

(function () {
  var headerHight = 72; //ヘッダの高さ
  // #で始まるアンカーをクリックした場合に処理

  $('a[href^="#"]').click(function () {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得

    var href = $(this).attr("href"); // 移動先を取得

    var target = $(href == "#" || href == "" ? "html" : href); // 移動先を数値で取得

    var position = target.offset().top - headerHight; //ヘッダの高さ分位置をずらす
    // スムーススクロール

    $("body,html").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
});

},{}]},{},[1]);
